import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserLogList } from "../../../services/ApiService";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";

const UserLog = (props) => {
    const pageSize = 25;
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState({
        items: [],
        headers: [
            {
                field: 'dateTime', headerName: translate(lang, 'logDate'), flex: 1, restricted: [], valueGetter: (params) => {
                    return DateTime.fromISO(params.row.created_at).toFormat('dd/MMM/yyyy HH:mm:ss');
                }
            },
            {
                field: 'action', headerName: translate(lang, 'logDate'), flex: 1, restricted: [], valueGetter: (params) => {
                    return params.row.action;
                }, renderCell: (params) => {
                    return <span className="text-capitalize">{params.value}</span>
                }
            },
            {
                field: 'name', headerName: translate(lang, 'name'), flex: 1, restricted: [], valueGetter: (params) => {
                    return params.row.user?.name;
                }
            },
            {
                field: 'username', headerName: translate(lang, 'username'), flex: 1, restricted: [], valueGetter: (params) => {
                    return params.row.user?.username;
                }
            },
            {
                field: 'email', headerName: translate(lang, 'email'), flex: 1, restricted: [], valueGetter: (params) => {
                    return params.row.user?.email;
                }
            },
            {
                field: 'role', headerName: translate(lang, 'role'), flex: 1, restricted: ['agent', 'player'], valueGetter: (params) => {
                    return params.row.user?.role === "agent" ? translate(lang, 'user') : params.row.user?.role;
                }, renderCell: (params) => {
                    return <span className="text-capitalize">{params.value}</span>
                }
            },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "auth_log_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    const [tableOptions, setTableOptions] = useState({
        isServer: false,
        pageSize: pageSize,
        pageCurrent: 1,
        page: 0,
        pageCount: 1,
        pageInfo: { totalRowCount: 0 },
        loading: false,
    })

    useEffect(() => {
        if (['master', 'superadmin'].includes(props.auth.data.role)) {
            getList()
        } else {
            history.push('/entry')
        }
    }, [searchText])

    async function getList() {
        let query = {};
        if (searchText !== '') {
            query = {
                username: searchText,
                name: searchText,
                role: searchText,
                isSearch: true,
            }
        }
        let res = await getUserLogList(query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data.data,
            })
            setExportData({
                ...exportData,
                excelData: res.data.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    return (
        <>
            <div className="card">
                <div className="card-body w-100">
                    <MuiTable list={{ ...list }} options={{ ...tableOptions }} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)}></MuiTable>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth.auth,
    }
};


export default connect(mapStateToProps)(UserLog);