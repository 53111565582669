import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logo from "../../../images/logo-text-white.png";


const NavHader = () => {
	const logoSize = {w: 50, h: 50};
	const [toggle, setToggle] = useState(false);
	const { navigationHader, openMenuToggle, background } = useContext(
		ThemeContext
	);
	return (
		<div className="nav-header">
			<Link to="/dashboard" className="brand-logo">
				{background.value === "dark" || navigationHader !== "color_1" ? (
					<>
						<img className="nav-header-logo" src={logo} width={logoSize.w} height={logoSize.h} alt="logo"></img>
					</>
				) : (
					<>
						<img className="nav-header-logo" src={logo} width={logoSize.w} height={logoSize.h} alt="logo"></img>
					</>
				)}
			</Link>

			<div
				className="nav-control"
				onClick={() => {
					//   setToggle(!toggle);
					//   openMenuToggle();
				}}
			>
				<div className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHader;
