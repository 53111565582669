/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
/// Link
import { Link, useHistory } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { companyName } from "../../../utils/info";
import { translate } from "../../../utils/translate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faClipboardList, faFlagCheckered, faGears, faIdBadge, faMountain, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const lang = props.lang;
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    getMenuToggle,
    openMenuToggle
  } = useContext(ThemeContext);
  const history = useHistory();
  const [path, setPath] = useState("");
  const [pathHeader, setPathHeader] = useState("");
  const [toggle, setToggle] = useState(getMenuToggle())
  const boxRef = useRef(null);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var rightContainer = document.querySelector(".header-right-container");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
    rightContainer.addEventListener("click", () => toggleMenuFunc(true));
  }, []);
  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
      // return aaa.classList.toggle("menu-toggle");
    } else {
      aaabackdrop.classList.remove('d-none');
      return aaa.classList.add("menu-toggle");
    }
  }
  let scrollPosition = useScrollPosition();
  /// Path
  // path = path.split("/");
  // path = path[path.length - 1];

  useEffect(() => {
    history.listen((location) => {
      setPathHeader(location.pathname.split('/')[1]);
      console.log(location.pathname.split('/')[1])
      // console.log(location.pathname, settings, 'path', settings.indexOf(location.pathname));
      setPath(location.pathname);
    });
    if (!path) {
      setPath(history.location.pathname);
    }
    if (!pathHeader) {
      setPathHeader(history.location.pathname.split('/')[1]);
    }
  }, [history]);

  useEffect(() => {
    // console.log("refresh", pathHeader)
  }, [pathHeader])
  /// Active menu
  let entry = ["entry",];
  let user = ["user",];
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
      ref={boxRef}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${entry.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <FontAwesomeIcon icon={faClipboardList} className="nav-icon" />
              <span className="nav-text">{translate(lang, 'database')}</span>
            </Link>
            <ul className={`mm-collapse ${entry.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(entry)+/i) ? "mm-active" : ""}`} to="/entry">{translate(lang, 'list')}</Link></li>
            </ul>
          </li>
          {['admin', 'superadmin', 'master'].includes(props.auth?.data?.role) ? (
            <li className={`${user.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <FontAwesomeIcon icon={faUser} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'user')}</span>
              </Link>
              <ul className={`mm-collapse ${user.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(list)+/i) ? "mm-active" : ""}`} to="/user/list">{translate(lang, 'list')}</Link></li>
                {['superadmin', 'master'].includes(props.auth?.data?.role) ? (
                  <li><Link className={`${path.match(/(log)+/i) ? "mm-active" : ""}`} to="/user/log">{translate(lang, 'log')}</Link></li>
                ) : <></>}
              </ul>
            </li>) : <></>
          }
        </MM>
        {/* <div className="copyright">
          <p><strong>{companyName}</strong> © All Rights Reserved</p>
        </div> */}
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth.auth,
  };
};

export default connect(mapStateToProps)(SideBar);
