import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Setting from "./layouts/Setting";
import routes from "../utils/route";
import { ThemeContext } from "../context/ThemeContext";
import { connect } from "react-redux";
import EntryList from "./pages/entry/EntryList";

const Markup = (props) => {
  const { menuToggle } = useContext(ThemeContext);
  // const routes = [
  //   /// Dashboard
  //   { path: "", component: Home},
  // ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`}
          // style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => {
                return (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                )
              })}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth.auth,
  }
};

export default connect(mapStateToProps)(Markup);
