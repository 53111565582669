/// Pages

import Dashboard from "../jsx/pages/Dashboard";
import EntryForm from "../jsx/pages/entry/EntryForm";
import EntryImport from "../jsx/pages/entry/EntryImport";
import EntryList from "../jsx/pages/entry/EntryList";
import UserForm from "../jsx/pages/user/UserForm";
import UserList from "../jsx/pages/user/UserList";
import UserLog from "../jsx/pages/user/UserLog";

const routes = [
    /// Dashboard
    { url: "", component: Dashboard, restrict: [], regex: 'dashboard', name: "dashboard" },
    { url: "dashboard", component: Dashboard, restrict: [], regex: 'dashboard', name: "dashboard" },
    { url: "user/list", component: UserList, restrict: ['superadmin', 'admin', 'master'], regex: 'user', name: "user" },
    { url: "user/log", component: UserLog, restrict: ['superadmin', 'master'], regex: 'user', name: "user" },
    { url: "user/:type/:id?", component: UserForm, restrict: ['superadmin', 'admin', 'master'], regex: 'user', name: "user" },
    { url: "entry/import/", component: EntryImport, restrict: ['superadmin', 'admin', 'master'], regex: 'entry', name: "database" },
    { url: "entry/:type/:id?", component: EntryForm, restrict: ['superadmin', 'admin', 'master'], regex: 'entry', name: "database" },
    { url: "entry", component: EntryList, restrict: [], regex: 'entry', name: "database" },
];

export default routes;