import React, { useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridActionsCellItem, GridPaginationModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { translate } from '../../../utils/translate';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';
import Swal from "sweetalert2";
import { CircularProgress, Pagination, Stack } from "@mui/material";

const MuiTable = ({ lang, list, exportData, options, onTextSearched, onActionTaken, onPageChanged }) => {
    let [opts, setOpts] = useState({
        filter: {
            search: options && (options.search === true || options.search === false) ? options.search : true,
        },
        exports: {
            excel: options && options.excel ? options.excel : false,
            pdf: false,
            txt: false,
        },
        loading: options && options.loading ? options.loading : false,
    })
    const [headers, setHeaders] = useState(list && list.headers ? [...list.headers] : [{ field: 'name', headerName: translate(lang, 'name'), restricted: [] },]);
    const [items, setItems] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: options && options?.page ? options?.page : 0,
        pageSize: options && options?.pageSize,
        pageCount: options && options?.pageCount ? options?.pageCount : 1,
    });
    const [isLoading, setIsLoading] = useState(false);
    const mapPageToNextCursor = React.useRef({});
    const [rowCountState, setRowCountState] = useState(
        options?.pageInfo?.totalRowCount || 0,
    );
    const exportExcel = () => {
        let exFileName = `${exportData.name}_${DateTime.now().toUnixInteger().toString()}.xlsx`;
        console.log(exFileName);
        return;
        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(exportData.excelData && exportData.excelData.length > 0 ? exportData.excelData : []);
        if (exportData.hasCustom) {
            exportData.customRows.map((cr) => {
                XLSX.utils.sheet_add_aoa(ws, [cr.customData], { origin: cr.origin ?? -1 })
            })
        }
        XLSX.utils.book_append_sheet(wb, ws, "Report");
        XLSX.writeFile(wb, exFileName)
    }

    const debounceInput = (event) => {
        const newValue = event;
        setSearchText(newValue);
        // Clear the previous timeout if exists
        clearTimeout(debouncedValue);

        // Set a new timeout to update debouncedValue after a delay
        const timeoutId = setTimeout(() => {
            setDebouncedValue(newValue);
            // onTextSearched(event);
            onActionTaken({ value: event, type: 'search' });
            // handleSearch(event)
        }, 0); // Adjust the delay (in milliseconds) as needed
        setDebouncedValue(timeoutId);
    };

    const handlePaginationModelChange = (newPaginationModel) => {
        // We have the cursor, we can allow the page transition.
        let temp = { ...newPaginationModel };
        // temp.page += 1;
        setPaginationModel({ ...temp });
        onPageChanged({ ...temp });
        // if (
        //     newPaginationModel.page === 0 ||
        //     mapPageToNextCursor.current[newPaginationModel.page - 1]
        // ) {
        // }
    };

    useEffect(() => {
        console.log(list);
        setItems(list.items);
    }, [list.items])

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            options?.pageInfo?.totalRowCount !== undefined
                ? options?.pageInfo?.totalRowCount
                : prevRowCountState,
        );
    }, [options?.pageInfo?.totalRowCount, setRowCountState]);

    useEffect(() => {
        console.log(options);
        setPaginationModel({
            ...paginationModel,
            page: options.page,
            pageCount: options.pageCount,
        })
    }, [options?.page]);

    return <>
        <div className="d-flex flex-column col-12 w-100" style={{ minHeight: '10rem' }}>
            <div className="d-flex flex-column flex-md-row col-12 mb-2">
                {/* Filter and exports */}
                <div className="col-12 col-md-8">
                    <div>
                        {
                            opts.exports.excel ? (<button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportExcel')}</button>) :
                                opts.exports.pdf ? (<button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportPDF')}</button>) :
                                    opts.exports.txt ? (<button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportTxt')}</button>) :
                                        (<></>)
                        }
                    </div>
                </div>
                {
                    opts.filter.search ?
                        (<div className="col-12 col-md-4 d-flex gap-2">
                            <input type="text" className="form-control" placeholder={translate(lang, 'searchHere')} onChange={(e) => setSearchText(e.target.value)} /> {/**onChange={(e) => { debounceInput(e.target.value) }} */}
                            <button className="btn btn-primary btn-sm" onClick={() => debounceInput(searchText)}>{translate(lang, "search")}</button>
                        </div>) :
                        (<></>)
                }
            </div>
            <div className="d-flex flex-row w-100" style={{ height: `${items.length > 0 ? 'auto' : '15rem'}` }}>
                {/* Table */}
                {
                    options?.isServer ? (
                        <DataGrid
                            rows={items.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page * paginationModel.pageSize) + paginationModel.pageSize))}
                            columns={headers}
                            rowCount={items.length}
                            paginationMode="server"
                            pageSizeOptions={[10]}
                            onPaginationModelChange={handlePaginationModelChange}
                            paginationModel={paginationModel}
                            loading={options?.loading ?? false}
                            components={{
                                LoadingOverlay: ({ }) => (
                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', paddingTop: "20px", backgroundColor: "#ececec3f" }}>
                                        {/* <CircularProgress /> */}
                                        <div class>
                                            <span class="loader"></span>
                                        </div>

                                    </div>
                                ),
                                // Pagination: ({ pagination, ...other }) => (
                                //     <Stack spacing={2} direction="row" alignItems="center" {...other}>
                                //         <Pagination
                                //             sx={{
                                //                 '& .MuiPaginationItem-page': { display: 'none' }, // Hide the page numbers
                                //                 '& .MuiPaginationItem-ellipsis': { display: 'none' }, // Hide the page numbers
                                //             }}
                                //             count={paginationModel.pageCount ?? 1}
                                //             page={paginationModel.page === 0 ? 1 : paginationModel.page}
                                //             onChange={(e, newPage) => { console.log(newPage); setPaginationModel({ ...paginationModel, page: newPage }); onPageChanged(newPage) }}
                                //         />
                                //         {/* <select
                                //             value={pageSize}
                                //             onChange={handleChangePageSize}
                                //             style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                //         >
                                //             {[5, 10, 25].map((size) => (
                                //                 <option key={size} value={size}>
                                //                     {size}
                                //                 </option>
                                //             ))}
                                //         </select> */}
                                //     </Stack>
                                // ),
                            }}
                        // components={{
                        //     Pagination: (props) => (<Pagination {...props} sx={{
                        //         '& .MuiPaginationItem-page': { display: 'none' }, // Hide the page numbers
                        //     }}></Pagination>)
                        // }}
                        />) : (<DataGrid
                            // loading={dataLoading}
                            rows={items}
                            columns={headers}
                        // initialState={{
                        //     pagination: { paginationModel: { pageSize: 10, page: 0 } }
                        // }}
                        />)
                }
            </div>
        </div>
    </>
}

export default MuiTable;