import { basicAxiosInstance, noTokenAxiosInstance } from '../services/AxiosInstance';

export async function getUserLogList(params) {
    return basicAxiosInstance.get(`/user/log`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getUserList(params) {
    return basicAxiosInstance.get(`/user/list`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getUserById(id) {
    return basicAxiosInstance.get(`/user/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createUser(body) {
    return basicAxiosInstance.post(`/user/add_user`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editUser(id, body) {
    return basicAxiosInstance.put(`/user/edit_user/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeUser(id, body) {
    return basicAxiosInstance.put(`/user/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getEntries(params, cancelToken) {
    return basicAxiosInstance.get(`/entry/get_entry`, {
        params: params,
        cancelToken: cancelToken
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function addEntries(body) {
    return basicAxiosInstance.post(`/entry/add_entry`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function importEntries(body) {
    return basicAxiosInstance.post(`/entry/import_entry`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editEntry(id, body) {
    return basicAxiosInstance.put(`/entry/edit_entry/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function checkEntryCounter() {
    return basicAxiosInstance.put(`/entry/check_entry_count/`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeEntry(id) {
    return basicAxiosInstance.put(`/entry/remove_entry/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createUserActionLog(action) {
    return basicAxiosInstance.post(`/action/${action}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

