// const url = 'http://localhost:3700';
const url = 'https://api.sky-eye.my';
const version = '1.0';

const api = {
    login: url + '/users/signin',
    users: url + '/users',
    entry: url + '/entry',
}

export  { api, url, version };