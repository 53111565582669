import { DialogContent, useTheme } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { checkEntryCounter, getEntries, removeEntry } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";
import axios from 'axios';

const EntryList = (props) => {
    const pageSize = 100;
    const lang = props.lang;
    const abortController = new AbortController();
    const signal = abortController.signal;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [cancelBufferTokenSource, setCancelBufferTokenSource] = useState(null);
    const [list, setList] = useState({
        items: [],
        headers: [
            {
                field: 'name', headerName: translate(lang, 'name'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    if (params.row.name !== "[]" && params.row.name !== "{}" && params.row.name) {
                        let items = JSON.parse(params.row.name);
                        items = items.join(',');
                        return items
                    } else {
                        return "-"
                    }
                },
            },
            {
                field: 'nric', headerName: translate(lang, 'nric'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    if (params.row.nric !== "[]" && params.row.nric !== "{}" && params.row.nric) {
                        let items = JSON.parse(params.row.nric);
                        items = items.join(',');
                        return items
                    } else {
                        return "-"
                    }
                },
            },
            {
                field: 'email', headerName: translate(lang, 'email'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    if (params.row.email !== "[]" && params.row.email !== "{}" && params.row.email) {
                        let items = JSON.parse(params.row.email);
                        items = items.join(',');
                        return items
                    } else {
                        return "-"
                    }
                },
            },
            {
                field: 'phone', headerName: translate(lang, 'phone'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    if (params.row.phone !== "[]" && params.row.phone !== "{}" && params.row.phone) {
                        let items = JSON.parse(params.row.phone);
                        items = items.join(',');
                        return items
                    } else {
                        return "-"
                    }
                },
            },
            {
                field: 'address', headerName: translate(lang, 'address'), restricted: [], flex: 1, minWidth: 300, valueGetter: (params) => {
                    if (params.row.address !== "[]" && params.row.address !== "{}" && params.row.address) {
                        let items = JSON.parse(params.row.address);
                        items = items.join(',');
                        return items
                    } else {
                        return "-"
                    }
                },
            },
            {
                field: 'actions', type: 'actions', headerName: translate(lang, 'action'), restricted: [], flex: 1, minWidth: 150, getActions: (params) => [
                    // <Link to={`/user/edit/${params.id}`} className=" btn btn-sm btn-warning cursor-pointer">{translate(lang, 'edit')}</Link>,
                    // <button onClick={() => removeItem(params.id)} className="btn btn-sm btn-danger cursor-pointer">{translate(lang, 'delete')}</button>
                    <button onClick={() => toggleModal(true, params.row)} className="btn btn-sm btn-info cursor-pointer">{translate(lang, 'view')}</button>
                ]
            },
        ],
    });
    const [tableOptions, setTableOptions] = useState({
        isServer: true,
        pageSize: pageSize,
        pageCurrent: 1,
        page: 0,
        pageCount: 1,
        pageInfo: { totalRowCount: 0 },
        loading: false,
    })
    const [exportData, setExportData] = useState({
        name: "entry_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });
    const [modalInfo, setModalInfo] = React.useState({
        isOpen: false,
        name: [],
        address: [],
        phone: [],
        nric: [],
        email: [],
    });
    useEffect(() => {
        if (searchText !== "") {
            return;
            setTableOptions({
                ...tableOptions,
                loading: true,
            })
            getList({ searchText: searchText, pageSize: pageSize, pageCurrent: 1 })
        }
    }, [searchText])

    useEffect(() => {
        // Cleanup function to cancel previous request
        return () => {
            if (cancelTokenSource) {
                console.log('Component unmounted - cancelled');
                cancelTokenSource.cancel('Component unmounted');
            }
        };
    }, [cancelTokenSource]);

    async function getList(tableOpt, triggerSearched = false) {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('New request triggered');
            console.log('New request triggered - cancelled');
        }
        if (!tableOpt.searchText || tableOpt.searchText === '') {
            setTableOptions({
                ...tableOptions,
                loading: false,

            });
            setList({ ...list, items: [] })
            return;
        }
        let query = {};
        if (tableOpt.searchText !== '' || searchText !== '') {
            query = {
                name: tableOpt.searchText ?? searchText,
                address: tableOpt.searchText ?? searchText,
                phone: tableOpt.searchText ?? searchText,
                email: tableOpt.searchText ?? searchText,
                nric: tableOpt.searchText ?? searchText,
                isSearch: true,
                limit: tableOpt.pageSize,
                page: tableOpt.pageCurrent,
            }
        }
        try {
            const newCancelTokenSource = axios.CancelToken.source();
            setCancelTokenSource(newCancelTokenSource); // Save the new cancel token source
            let res = await getEntries(query, newCancelTokenSource.token);
            if (res?.status === 'success') {
                let tempList = list;
                tempList.items = res.data.data.map((dt) => ({ ...dt, selected: false }))
                setList({ ...tempList })
                let tblOpt = {
                    ...tableOptions,
                    searchText: tableOpt.searchText ?? searchText,
                    loading: false,
                    pageCurrent: 1,
                    page: triggerSearched ? 0 : tableOpt.page ?? 0,
                    pageInfo: { totalRowCount: 1000 }
                }
                if (res.data.data.length > pageSize) {
                    if (tableOpt.pageMoved) {
                        if (tableOpt.pageMoved === 'go') {
                            tblOpt.pageCount += 1;
                        }
                    }
                    if (triggerSearched) {
                        tblOpt.pageCount += 1;
                    }
                }
                setTableOptions({ ...tblOpt })
                setExportData({
                    ...exportData,
                    excelData: res.data.data,
                });
                // buff more data 
                bufferList(1, tblOpt, tempList);
            } else {
                setTableOptions({
                    ...tableOptions,
                    loading: false,
                    pageInfo: { totalRowCount: 0 }
                })
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request cancelled:', error.message);
            } else {
                // setError(error);
                // setLoading(false);
            }
        }
    }

    function bufferList(counter, tableOpt, newList) {
        if (cancelBufferTokenSource) {
            console.log('buffer list New request triggered - cancelled', cancelBufferTokenSource);
            cancelBufferTokenSource.cancel('New request triggered');
        }
        let query = {};
        if (tableOpt.searchText !== '' || searchText !== '') {
            query = {
                name: tableOpt.searchText ?? searchText,
                address: tableOpt.searchText ?? searchText,
                phone: tableOpt.searchText ?? searchText,
                email: tableOpt.searchText ?? searchText,
                nric: tableOpt.searchText ?? searchText,
                cursor: newList.items[newList.items.length - 1].id,
                isSearch: true,
                limit: tableOpt.pageSize,
                page: tableOpt.pageCurrent + counter,
            }
        }
        try {
            const newCancelBufferTokenSource = axios.CancelToken.source();
            setCancelBufferTokenSource(newCancelBufferTokenSource); // Save the new cancel token source
            getEntries(query, newCancelBufferTokenSource.token).then((res) => {
                if (res?.status === 'success') {
                    let bufferData = res.data.data.map((dt) => ({ ...dt, selected: false }));
                    let tempList = newList;
                    tempList.items = tempList.items.concat(bufferData)
                    setList({ ...tempList })
                    let tblOpt = {
                        ...tableOpt,
                    }
                    if (res.data.data.length === pageSize) {
                        tblOpt.pageCount += 1;
                    } else {
                        console.log('completed')
                        // tblOpt.pageCount -= 1;
                    }
                    setTableOptions({ ...tblOpt })
                    setExportData({
                        ...exportData,
                        excelData: [...exportData.excelData, ...res.data.data],
                    });
                    if (res.data.data.length === pageSize) {
                        // recursive for buffing more data
                        // && counter < 3 stopper
                        // console.log(counter);
                        bufferList(counter + 1, tblOpt, tempList);
                    }
                } else {
                    setTableOptions({
                        ...tableOptions,
                        loading: false,
                        pageInfo: { totalRowCount: 0 }
                    })
                }
            }).catch((err) => {
                console.log(err)
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Buffer Request cancelled:', error.message);
            } else {
                // setError(error);
                // setLoading(false);
            }
        }
    }

    function onTextSearched(value) {
        return;
        // setSearchText(value);
    }

    function onActionTaken(e) {
        if (e.type === 'search') {
            if (cancelBufferTokenSource) {
                console.log('button pressed New request triggered - cancelled');
                cancelBufferTokenSource.cancel('New request triggered');
            }
            setSearchText(e.value);
            setTableOptions({
                ...tableOptions,
                loading: true,
            })
            getList({ searchText: e.value, pageSize: pageSize, pageCurrent: 1, page: 0 }, true);
        }
        if (e.type === 'remove') {
            removeItem()
        } else if (e.type === 'edit') {
            history.push('');
        }
    }

    function onPageChanged(value) {
        // let prev = tableOptions.page;
        // let move = value - prev > 0 ? "go" : "back"
        // setTableOptions({
        //     ...tableOptions,
        //     loading: true,
        // })
        // getList({ pageSize: pageSize, page: value, pageCurrent: value, pageMoved: move, })
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeEntry(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            }
        })
    }

    function checkRowCounter() {
        let res = checkEntryCounter()
        // if (res.status === 'success') {
        //     promptMessage(lang, 'success', res.message)
        // } else {
        //     promptMessage(lang, 'error', res.message)
        // }
    }

    const selectEntry = (index) => {
        let temp = list;
        let current = temp.items.find((item) => item.selected);
        let clicked = temp.items.find((item, iIndex) => iIndex === index);
        if (current) {
            current.selected = false;
        }
        clicked.selected = true;
        setList({ ...list, items: [...temp.items] })
    }

    const debounceInput = (event) => {
        setList({ ...list, items: [] });
        setTableOptions({
            ...tableOptions,
            loading: true,
        })
        const newValue = event;

        // Clear the previous timeout if exists
        clearTimeout(debouncedValue);

        // Set a new timeout to update debouncedValue after a delay
        const timeoutId = setTimeout(() => {
            setDebouncedValue(newValue);
            // onTextSearched(event);
            // setSearchText(newValue);

            onActionTaken({ value: event, type: 'search' });
            // handleSearch(event)
        }, 1000); // Adjust the delay (in milliseconds) as needed
        setDebouncedValue(timeoutId);
    };

    function toggleModal(open, info) {
        let temp = { ...modalInfo };
        temp.isOpen = open;
        if (info) {
            temp.name = info.name ? JSON.parse(info.name) : [];
            temp.address = info.address ? JSON.parse(info.address) : [];
            temp.nric = info.nric ? JSON.parse(info.nric) : [];
            temp.email = info.email ? JSON.parse(info.email) : [];
            temp.phone = info.phone ? JSON.parse(info.phone) : [];
        } else {
            temp.name = [];
            temp.address = [];
            temp.nric = [];
            temp.email = [];
            temp.phone = [];
        }
        setModalInfo({ ...temp })
    }

    return (
        <>
            {/* <Modal
                open={modalInfo.isOpen}
                onClose={() => toggleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-box">
                    <p>something</p>
                </Box>
            </Modal> */}
            <Dialog open={modalInfo.isOpen} maxWidth="lg">
                {/* <DialogTitle>Set backup account</DialogTitle> */}
                <DialogContent className="modal-box p-0">
                    <div className="d-flex justify-content-end">
                        <button className="btn hover-button-red" onClick={() => toggleModal(false)}><i className="fas fa-times"></i></button>
                    </div>
                    <hr className="m-0"></hr>
                    <div className="p-3 pb-0">
                        <h4>{translate(lang, 'name')}</h4>
                        {modalInfo.name.length > 0 ? modalInfo.name.map((name, index) => (<p key={`name_${index}`}><span>{name}</span></p>)) : (<p>-</p>)}
                        <h4>{translate(lang, 'nric')}</h4>
                        {modalInfo.nric.length > 0 ? modalInfo.nric.map((nric, index) => (<p key={`nric_${index}`}><span>{nric}</span></p>)) : (<p>-</p>)}
                        <h4>{translate(lang, 'phone')}</h4>
                        {modalInfo.phone.length > 0 ? modalInfo.phone.map((phone, index) => <p key={`phone_${index}`}><span>{phone}</span></p>) : (<p>-</p>)}
                        <h4>{translate(lang, 'email')}</h4>
                        {modalInfo.email.length > 0 ? modalInfo.email.map((email, index) => <p key={`email_${index}`}><span>{email}</span></p>) : (<p>-</p>)}
                        <h4>{translate(lang, 'address')}</h4>
                        {modalInfo.address.length > 0 ? <p key={`address`}><span>{modalInfo.address.join(',')}</span></p> : (<p>-</p>)}
                    </div>
                </DialogContent>

            </Dialog>
            <div className="card">
                <div className="card-header">
                    {['superadmin', 'master', 'admin'].includes(props.auth.data.role) ? (
                        <div className="d-flex flex-row gap-2">
                            <Link to="/entry/create" className="btn btn-primary btn-sm">{translate(lang, 'addEntry')}</Link>
                            <Link to="/entry/import" className="btn btn-primary btn-sm">{translate(lang, 'importEntry')}</Link>
                        </div>
                    ) : <></>}
                    {/* <div className="col-12 col-md-4 d-flex gap-2">
                        <input type="text" className="form-control" placeholder={translate(lang, 'searchHere')} onChange={(e) => debounceInput(e.target.value)} />
                        <button className="btn btn-primary btn-sm" onClick={() => searchList()}>{translate(lang, "search")}</button>
                    </div> */}
                    {/* <div>
                        <button className="btn btn-primary btn-sm" onClick={() => checkRowCounter()}>{"Trigger Me"}</button>
                    </div> */}
                </div>
                <div className="card-body w-100 col-12">
                    <MuiTable list={{ ...list }} options={{ ...tableOptions }} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)} onPageChanged={(e) => onPageChanged(e)}></MuiTable>
                    {/* <div className="entry-list-view-container">
                        <ul className="p-2">
                            {list.items.length > 0 ? list.items.map((item, iIndex) => {
                                return (
                                    <li className={`entry-list-item ${item.selected ? 'selected' : ''}`} onClick={() => selectEntry(iIndex)} onDoubleClick={() => toggleModal(true, item)}>
                                        <div className="">
                                            {[...Object.keys(item)].map((key) => {
                                                if (['id', 'selected'].includes(key)) return;
                                                let v = item[key];
                                                let str = '';
                                                if (v !== "[]" && v !== "{}" && v) {
                                                    str = JSON.parse(v);
                                                    str = str.join(',');
                                                } else {
                                                    str = "-";
                                                }
                                                return (
                                                    <div key={`entry_${key}_${iIndex}`}>
                                                        <div className="text-uppercase h5 m-0">{key}</div>
                                                        <p>{str}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </li>
                                )
                            }) : tableOptions.loading ? (
                                <span class="loader"></span>
                            ) : (<span>No Item Founded</span>)}
                        </ul>
                    </div> */}
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth.auth,
    }
};


export default connect(mapStateToProps)(EntryList);