import Swal from "sweetalert2";
import { translate } from "./translate";
import * as XLSX from 'xlsx';

export const promptMessage = (lang, type, msg, optional = {}) => {
    let basic = {
        showConfirmButton: false,
        timer: 2500,
    }
    basic = { ...basic, ...optional }
    Swal.fire({
        icon: type,
        title: translate(lang, type),
        text: lang === "EN" ? msg.toUpperCase() : msg,
        ...basic,
    });
}

export const genderOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'male'), value: "male", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'female'), value: "female", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const questionairType = (lang) => {
    let types = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'chooseOne', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseMultiple'), value: 'chooseMulti', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'fillIn'), value: 'fillIn', isSelected: false, isHidden: false, },
    ]
    return types.filter(t => t.isHidden === false);
}

export const languageOptions = (lang) => {
    let langs = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'en', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseOne'), value: 'cn', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'chooseOne'), value: 'bm', isSelected: false, isHidden: false, },
    ]
    return langs.filter(l => l.isHidden === false);
}

export const referenceOptions = (lang) => {
    let ref = [
        { id: 1, label: translate(lang, 'url'), value: 'url', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'image'), value: 'image', isSelected: false, isHidden: true, },
    ]
    return ref.filter(l => l.isHidden === false);
}

export const userRoleOptions = (lang, uRole) => {
    let userRole = uRole;
    let roles = [
        { id: 1, label: translate(lang, 'master'), value: 'master', allowed: ['master'], isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'admin'), value: 'admin', allowed: ['master', 'admin'], isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'user'), value: 'agent', allowed: ['master', 'admin', 'agent'], isSelected: false, isHidden: false, },
    ]
    return roles.filter(l => l.isHidden === false && l.allowed.includes(userRole));
}

export const headerOptions = (lang) => {
    let headers = [
        { id: 1, label: translate(lang, 'name'), value: 'name', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'nric'), value: 'nric', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'phone'), value: 'phone', isSelected: false, isHidden: false, },
        { id: 4, label: translate(lang, 'address'), value: 'address', isSelected: false, isHidden: false, },
        { id: 5, label: translate(lang, 'email'), value: 'email', isSelected: false, isHidden: false, },
        { id: 6, label: translate(lang, 'other'), value: 'other', isSelected: false, isHidden: false, },
    ]
    return headers.filter(l => l.isHidden === false);
}

export const findDuplicatesInArray = (list, target) => {
    let results;
    results = list.reduce((acc, item) => {
        if (typeof acc[item[target]] !== 'undefined') {
            acc[item[target]]++;
            return acc;
        } else {
            acc[item[target]] = 1;
            return acc;
        }
    }, {})
    var tempArr = [];
    for (var x in results) {
        tempArr.push({
            key: x,
            value: results[x]
        });
    }
    return tempArr;
}

export const readExcelData = async (file) => {
    if (file) {
        const data = await readFile(file);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet is the one you want to read
        
        const sheets = workbook.SheetNames;
        let jsonData = {};
        sheets.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            jsonData[sheetName] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        })
        return jsonData;
        // setExcelData(jsonData);
    }
}

export const readFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            resolve(new Uint8Array(e.target.result));
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
}