import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserList, removeUser } from "../../../services/ApiService";
import { promptMessage, userRoleOptions } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";

const UserList = (props) => {
    const pageSize = 25;
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState({
        items: [],
        headers: [
            { field: 'name', headerName: translate(lang, 'name'), flex: 1, restricted: [], },
            { field: 'phone', headerName: translate(lang, 'phone'), flex: 1, restricted: [], },
            { field: 'email', headerName: translate(lang, 'email'), flex: 1, restricted: [], },
            {
                field: 'role', headerName: translate(lang, 'role'), flex: 1, restricted: ['agent', 'player'], valueGetter: (params) => {
                    return params.row.role === "agent" ? translate(lang, 'user') : params.row.role;
                }
            },
            {
                field: 'actions', type: 'actions', headerName: translate(lang, 'action'), flex: 1, restricted: ['agent', 'player'], getActions: (params) => [
                    <Link to={`/user/edit/${params.id}`} className=" btn btn-sm btn-warning cursor-pointer">{translate(lang, 'edit')}</Link>,
                    <button onClick={() => removeItem(params.id)} className="btn btn-sm btn-danger cursor-pointer">{translate(lang, 'delete')}</button>
                ]
            },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "user_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });
    const [tableOptions, setTableOptions] = useState({
        isServer: false,
        pageSize: pageSize,
        pageCurrent: 1,
        page: 0,
        pageCount: 1,
        pageInfo: { totalRowCount: 0 },
        loading: false,
    })

    useEffect(() => {
        if (['superadmin', 'admin', 'master'].includes(props.auth?.data?.role)) {
            setTableOptions({
                ...tableOptions, loading: true
            })
            getList(tableOptions)
        } else {
            history.push('/entry')
        }
    }, [searchText])

    async function getList(tableOpt) {
        let query = {};
        if (searchText !== '') {
            query = {
                username: searchText,
                name: searchText,
                phone: searchText,
                email: searchText,
                user_type: searchText,
                start_date: searchText,
                end_date: searchText,
                // page: tableOpt.pageSize,
                // limit: tableOpt.pageCurrent,
            }
        }
        let res = await getUserList(query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data.data,
            })
            setTableOptions({
                ...tableOptions, loading: false
            })
            setExportData({
                ...exportData,
                excelData: res.data.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    function onPageChanged(value) {
        let tableOpt = tableOptions
        tableOpt.page = value;
        getList(tableOpt)
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeUser(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    {['superadmin', 'admin', 'master'].includes(props.auth?.data?.role) ? (
                        <div>
                            <Link to="/user/create" className="btn btn-primary btn-sm">{translate(lang, 'addUser')}</Link>
                        </div>
                    ) : <></>}
                </div>
                <div className="card-body w-100">
                    <MuiTable list={{ ...list }} options={{ ...tableOptions }} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)} onPageChanged={(e) => onPageChanged(e)}></MuiTable>
                    {/* <DataGrid rows={rows} columns={columns} /> */}
                    {/* <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={true}
                            fileData={fileData}
                        ></CustomTable>
                    </div> */}
                </div>
            </div >
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth.auth,
    }
};


export default connect(mapStateToProps)(UserList);