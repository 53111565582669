const en = {
    // common
    error: "Error",
    success: "Success",
    loading: "Loading",
    noResultFound: "No Result Found",
    goToPage: "Go To Page",
    page: "Page",
    show: "Show",
    search: "Search",
    searchHere: "Search here",
    searchTable: "Search Table",
    exportExcel: "Export Excel",
    exportPDF: "Export PDF",
    exportTxt: "Export TXT",
    index: "No.",
    name: "Name",
    fullName: "Full Name",
    age: "Age",
    identityCard: "Identity Card",
    birthDate: "Birth Date",
    phoneCode: "Phone Code",
    phone: "Phone",
    email: "Email",
    gender: "Gender",
    action: "Action",
    relationship: "Relationship",
    male: "Male",
    female: "Female",
    status: "Status",
    remark: "Remark",
    list: "List",
    type: "Type",
    description: "Description",
    icon: "Icon",
    placeholderEnterHere: "Enter here",
    placeholderChoose: "Choose one option",
    chooseOne: "Choose One",
    chooseMultiple: "Choose Multiple",
    fillIn: "Fill In",
    username: "Username",
    password: "Password",
    confirmPassword: "Confirm Password",
    file: "File",

    // form error message
    errorRequired: "This is required",
    passwordNotMatch: "Password Not Match",
    internalServerError: "Internal server error, please contact Admin",
    pinNotMatch: "Pin not match",
    noItemFound: "No item found",
    noQuestionFound: "No Question Found.",
    noAnswerOptionFound: "No Answer Option Found.",
    noSameColorAllowed: "Same color is not allowed in ratings, must be unique",
    distanceCannotEmpty: "Distance of course cannot be empty",

    // confirmation box message
    deleteConfirmation: "Delete Confirmation Box",
    deleteMessage: "Are you sure to remove this? Action cannot be undone.",

    // Message box return message

    // button
    create: "Create",
    add: "Add",
    remove: "Remove",
    edit: "Edit",
    delete: "Delete",
    back: "Back",
    save: "Save",
    import: "Import",
    view: "View",

    // language
    en: "EN",
    cn: "CN",
    bm: "BM",
    english: "English",
    chinese: "Chinese",
    melayu: "Bahasa Melayu",

    //options
    url: "URL",
    image: "Image",

    // project start
    // nav
    dashboard: "Dashboard",
    user: "User",
    entry: "Entry",
    database: "Database",
    settings: "Settings",
    log: "Log",

    addUser: "Add User",
    editUser: "Edit User",
    userDetails: "User Details",
    role: "Role",
    master: "Master",
    admin: "Admin",
    agent: "Agent",
    nric: "NRIC",
    phone: "Phone",
    address: "Address",
    addressDetails: "Address Details",
    other: "Other",

    description: "Description",
    addEntry: "Add Database",
    importEntry: "Import Database",
    editEntry: "Edit Database Data",
    entryDetails: "Database Details",
    noDataFound: "No data found, import excel file now",
    importExcelFile: "Import an excel file to view data...",
    fileData: "File Data",

    column: "Column",
    logDate: "Log Date",
}
export default en;  