import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { userRoleOptions } from "../../../utils/functions";
import { addEntries, createUser } from "../../../services/ApiService";
import Swal from "sweetalert2";

const EntryForm = (props) => {
    const lang = props.lang;
    const { auth } = props.auth;
    const { type, id } = useParams();
    const history = useHistory();
    const [data, setData] = useState({
        name: [""],
        nric: [""],
        email: [""],
        phone_text: "",
        phone: [],
        address_text: "",
        address: [],
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                name: [""],
                nric: [""],
                email: [""],
                phone_text: "",
                phone: [],
                address_text: "",
                address: [],
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/entry');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/entry');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = (id) => {

    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const addIntoGroup = (group, target) => {
        const list = { ...data };
        list[group].push(list[target]);
        list[target] = '';
        setData({ ...list })
    }

    const removeFromGroup = (group, index) => {
        const list = { ...data };
        list[group].splice(index, 1);
        setData({ ...list })
    }

    const submitForm = async () => {
        let check = true;
        // let error = { name: "", }
        // if (!data.name) {
        //     check = false;
        //     error.name = translate(lang, "errorRequired");
        // }
        if (check) {
            
            let res = await addEntries(data);
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/entry'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
        })
    }


    return (
        <>
            <div className="card col-12">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "entryDetails" : type === "edit" ? "editEntry" : "addEntry"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column">
                    <div className="form-group py-2">
                        <label className="form-label font-bigger text-black">{translate(lang, 'fullName')}</label>
                        <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: [e.target.value] }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label font-bigger text-black">{translate(lang, 'nric')}</label>
                        <input type="text" value={data.nric} onChange={(e) => { setData({ ...data, nric: [e.target.value] }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label font-bigger text-black">{translate(lang, 'email')}</label>
                        <input type="text" value={data.email} onChange={(e) => { setData({ ...data, email: [e.target.value] }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label font-bigger text-black">{translate(lang, 'phone')}</label>
                        <input type="tel" value={data.phone_text} onChange={(e) => { setData({ ...data, phone_text: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        <div className="d-flex flex-row gap-2 mt-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => addIntoGroup('phone', 'phone_text')}>{translate(lang, "add")}</button>
                            {/* <button type="button" className="btn btn-danger btn-sm">{translate(lang, "remove")}</button> */}
                        </div>
                        <div className="input-phone-group mt-2">
                            {data.phone && data.phone.map((tel, tIndex) => {
                                return (
                                    <span className="badge badge-info " key={`tel_${tIndex}`}>
                                        <span className="h5 m-0 text-white">{tel}</span>
                                        <span className="h5 m-0 text-white ps-2 cursor-pointer hover-button-red" onClick={() => removeFromGroup('phone', tIndex)}>
                                            <i class="fas fa-times"></i>
                                        </span>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label font-bigger text-black">{translate(lang, 'addressDetails')}</label>
                        <input type="text" value={data.address_text} onChange={(e) => { setData({ ...data, address_text: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        <div className="d-flex flex-row gap-2 mt-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => addIntoGroup('address', 'address_text')}>{translate(lang, "add")}</button>
                        </div>
                        <div className="input-phone-group mt-2">
                            {data.address && data.address.map((detail, dIndex) => {
                                return (
                                    <span className="badge badge-info " key={`detail_${dIndex}`}>
                                        <span className="h5 m-0 text-white">{detail}</span>
                                        <span className="h5 m-0 text-white ps-2 cursor-pointer hover-button-red" onClick={() => removeFromGroup('address_text', dIndex)}>
                                            <i class="fas fa-times"></i>
                                        </span>
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/entry')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(EntryForm);