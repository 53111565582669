import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from 'react-select';
import Swal from "sweetalert2";
import { importEntries } from "../../../services/ApiService";
import { headerOptions, readExcelData } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import { DateTime } from "luxon";

const EntryImport = (props) => {
    const lang = props.lang;
    const { auth } = props.auth;
    const { type, id } = useParams();
    const history = useHistory();
    const headerList = headerOptions(lang);
    const [data, setData] = useState({
        imported: false,
        inputs: [],
    });
    const [pagination, setPagination] = useState({
        perPage: 10,
        totalPage: 0,
        currentPage: 1,
    })
    const [importFile, setImportFile] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [error, setError] = useState({
        file: "",
        fileData: ""
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                imported: false,
                inputs: [],
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/entry');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/entry');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = (id) => {

    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file && ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) {
            setImportFile(file);
            setData({ ...data, imported: true })
        } else {
            setError({ ...error, file: "Incorrect Format, only excel or spreedsheet is supported" })
        }
    };

    const importData = async () => {
        console.log(DateTime.now().toFormat('HH:mm:ss'))
        let file = importFile;
        if (!file) {
            setError({ ...error, file: "No file uploaded." })
        } else {
            let excelData = await readExcelData(file);
            console.log(DateTime.now().toFormat('HH:mm:ss'))
            const sheetNames = Object.keys(excelData);
            let tempArr = [];
            sheetNames.map(sn => {
                excelData[sn] = excelData[sn].filter(item => item.length > 1);
                let totalPage = Math.ceil(excelData[sn].length / 10);
                let maxLength = excelData[sn].reduce((acc = 0, tmp) => tmp.length > acc ? tmp.length : acc, 0);
                tempArr.push({
                    name: sn,
                    data: [...excelData[sn]],
                    perPage: 10,
                    totalPage: totalPage,
                    currentPage: 1,
                    headerCount: maxLength,
                    headers: [...Array(maxLength)].map(counter => ""),
                })
                // setData({ ...data, headerCount: maxLength, headers: [...Array(maxLength)].map(counter => ""), inputs: [...tempArr] })
                // setPagination({
                //     ...pagination,
                //     totalPage: totalPage,
                // })
            })
            setData({ ...data, inputs: [...tempArr] })
            setIsLoadingData(false);
        }
    }

    const handleOptionSelected = (event, index, inIndex) => {
        let temp = { ...data };
        temp.inputs[inIndex].headers[index] = event.value;
        setData({ ...temp });
    }

    const removeCurrentRow = (index, inIndex) => {
        let temp = { ...data };
        temp.inputs[inIndex].data.splice(index, 1);
        setData({ ...temp });
    }

    const updateTablePage = (page, inIndex) => {
        let temp = { ...data };
        temp.inputs[inIndex].currentPage = page;
        setData({ ...temp })
    }

    const submitForm = async () => {
        let check = true;
        Swal.showLoading();
        let error = { file: "", fileData: "" }
        if (!importFile) {
            check = false;
            error.file = "No file uploaded.";
        }
        if (data.inputs.length < 1) {
            check = false;
            error.fileData = "No data to be imported";
        } else {
            data.inputs.map((input) => {
                if (input.headers.indexOf("") > -1) {
                    check = false;
                    error.fileData = "Please select all column representation."
                }
            })
        }
        setError({ ...error });
        if (check) {
            let tempData = [];
            [...Array(data.inputs.length)].map((a, iIndex) => {
                let inp = data.inputs[iIndex];
                return inp.data.map(dt => {
                    let tempObj = {}
                    dt.map((v, vIndex) => {
                        // console.log(tempObj[inp.headers[vIndex]])
                        if (tempObj[inp.headers[vIndex]]) {
                            tempObj[inp.headers[vIndex]] = [...tempObj[inp.headers[vIndex]], v.toString()];
                        } else {
                            tempObj[inp.headers[vIndex]] = [v.toString()];
                        }
                    })
                    if (Object.keys(tempObj).length > 0) tempData.push(tempObj)
                })
            });
            let maxCapOnce = 500;
            let res = { status: "", message: "" };
            console.log(tempData.length, maxCapOnce);
            // console.log(tempData, maxCapOnce, data.inputs);
            if (tempData.length > maxCapOnce) {
                let upTimes = Math.ceil(tempData.length / maxCapOnce);
                console.log(upTimes);
                for (let x = 0; x < upTimes; x++) {
                    // let payload = tempData[0].length > maxCapOnce ? tempData[0].splice(x * maxCapOnce, maxCapOnce) : tempData[0];
                    let payload = tempData.splice(0, maxCapOnce);
                    // console.log(payload)
                    await importEntries({ payload: payload, counter: x, max: upTimes });
                }
            } else {
                // console.log(tempData[0].length, tempData);
                await importEntries({ payload: tempData });
            }
            Swal.fire({
                title: translate(lang, 'success'),
                html: `<p class="text-uppercase">${res.message}</p>`,
                icon: 'success',
                timer: 1500,
            })
                .then(() => history.push('/entry'))
            // if (res && res.status === 'success') {
            //     resetForm();
            // } else {
            //     Swal.fire({
            //         title: translate(lang, 'error'),
            //         html: `<p class="text-uppercase">${res.message}</p>`,
            //         icon: 'error',
            //     })
            // }
        }
    }

    const resetForm = async () => {
        setData({
            imported: false,
            inputs: [],
            headerCount: 0,
            headers: [],
        })
    }


    return (
        <>
            <div className="card col-12">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, "importEntry")}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column">
                    <div className="form-group py-2">
                        <label className="form-label font-bigger text-black">{translate(lang, 'file')}</label>
                        <input type="file" accept=".xlsx,.csv" onChange={(e) => handleFileChange(e)} className="form-control h-100" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        <div className="mt-2 d-flex flex-column flex-md-row gap-2 align-items-center">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => { setIsLoadingData(true); importData() }}>{translate(lang, "import")}</button>
                            <p className="text-danger m-0">{error.file ?? ""}</p>
                        </div>
                    </div>
                    <div className="form-group py-2">
                        <div className="d-flex flex-column flex-md-row gap-3 align-items-center">
                            <label className="form-label font-bigger text-black m-0">{translate(lang, 'fileData')}</label>
                            <p className="text-danger m-0">{error.fileData ?? ""}</p>
                        </div>
                        <div className="card shadow">
                            <div className="card-body">
                                {data.inputs.length < 1 ? <p>{translate(lang, "noDataFound")}</p> : <></>}
                                {data.inputs.map((inp, inIndex) => {
                                    return (<Accordion key={`accordion_${inIndex}`}>
                                        <AccordionSummary style={{ borderBottom: "1px solid rgb(205, 205, 205)" }}>
                                            {inp.name}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                {isLoadingData ? <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> : (
                                                    <div>
                                                        <div className="overflow-auto mb-3">
                                                            <table className="table table-bordered table-striped table-responsive border border-dark">
                                                                <thead>
                                                                    <tr>
                                                                        {[...Array(inp.headerCount)].map((h, hIndex) => {
                                                                            return (
                                                                                <td className="header-border" key={`th_${inp.name}_${hIndex}`}>
                                                                                    <Select
                                                                                        key={`column_${inp.name}_${hIndex + 1}`}
                                                                                        className=""
                                                                                        value={headerList.filter(
                                                                                            (option) => option.value === inp.headers[hIndex]
                                                                                        )}
                                                                                        isDisabled={type === 'view' ? true : false}
                                                                                        onChange={(e) => handleOptionSelected(e, hIndex, inIndex)}
                                                                                        options={headerList}
                                                                                        placeholder={translate(lang, "chooseOne")}
                                                                                        styles={{
                                                                                            dropdownIndicator: (base, state) => ({
                                                                                                display: 'none',
                                                                                            }),
                                                                                            input: (base, state) => ({
                                                                                                ...base,
                                                                                                border: 0,
                                                                                            }),
                                                                                            control: (base, state) => ({
                                                                                                ...base,
                                                                                                height: "100%",
                                                                                                border: 0,
                                                                                                borderColor: "transparent",
                                                                                                boxShadow: "none",
                                                                                                "&:hover": {
                                                                                                    borderColor: "transparent",
                                                                                                    boxShadow: "none",
                                                                                                },
                                                                                                backgroundColor: "transparent",
                                                                                                backgroundImage: "none",
                                                                                            }),
                                                                                            placeholder: (base) => ({
                                                                                                ...base,
                                                                                                whiteSpace: "noWrap",
                                                                                                width: "100%",
                                                                                            }),
                                                                                            valueContainer: (base, state) => ({
                                                                                                ...base,
                                                                                                border: 0,
                                                                                                paddingLeft: 0,
                                                                                                paddingRight: 0,
                                                                                                borderColor: "transparent",
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    {/* {translate(lang, inp.headers[hIndex])} */}
                                                                                </td>
                                                                            )
                                                                        })}
                                                                        <td className="header-border text-center align-middle">
                                                                            {translate(lang, 'action')}
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {inp.data.map((dt, iIndex) => {
                                                                        let prevPage = (inp.perPage * (inp.currentPage - 1));
                                                                        let nextPage = (inp.perPage * inp.currentPage);
                                                                        if (iIndex >= prevPage && iIndex < nextPage) {
                                                                            // if ((iIndex * ) <= pagination.perPage && iIndex < iIndex + pageCount) {
                                                                            return (<tr key={`tr_${inp.name}_${iIndex}`}>
                                                                                {[...Array(inp.headerCount)].map((hc, hcIndex) => (
                                                                                    <td className="border border-dark text-nowrap" key={`td_${iIndex}_${hcIndex}`}>{dt[hcIndex] ?? ""}</td>
                                                                                ))}
                                                                                <td className="border border-dark cursor-pointer text-center" key={`td_${inp.name}_${iIndex}_${"action"}`}>
                                                                                    <span className="hover-button-red" onClick={() => removeCurrentRow(iIndex, inIndex)}>
                                                                                        <i className="fas fa-trash-alt"></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>)
                                                                        }
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <Stack spacing={2}>
                                                            <Pagination count={inp.totalPage} page={inp.currentPage} onChange={(e, v) => updateTablePage(v, inIndex)} />
                                                        </Stack>
                                                    </div>

                                                )
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `save`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/entry')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(EntryImport);